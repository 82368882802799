import React from "react";
import Header from "./header";

import "../styles/layout.css";
import * as styles from "./layout.module.css";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
        
            <a href="mailto:info@ogma.se">info@ogma.se</a><br></br>
            <a href="tel:+4645717575"> +46 0457 17575</a><br></br>
            <b>Visiting address:</b><br></br>
            <a href="http://maps.apple.com/?q=V%C3%A4stra%20Industrigatan%202%2C%20372%2031%20Ronneby%0A">Västra
                Industrigatan 2, <br></br>372 31 Ronneby<br></br></a>
        <br></br>
          © {new Date().getFullYear()}, OGMA AB 
        </div>
      </div>
    </footer>
  </>
);

export default Layout;
